import * as React from 'react';

import { cn } from '@/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import InputMask from 'inputmask';
import { Eye, EyeOff } from 'lucide-react';
import { v4 as uuid } from 'uuid';

const inputVariants = cva(
  'flex w-full text-sm outline-none transition-all file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default:
          'h-10 rounded-md border border-gray-200 bg-background px-3 py-2 focus-within:border-primary/40 focus-within:shadow-[0px_0px_3px_3px_#ffa13c1A]',
        underline: 'border-b-4 border-black p-2 text-primary focus-within:border-primary/40',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof inputVariants> & {
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
  };

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, variant, ...props }, ref) => {
  const id = React.useMemo(() => uuid(), []);

  return (
    <div
      className={cn(
        inputVariants({ variant }),
        className,
        props.disabled ? 'cursor-not-allowed bg-gray-100 text-black opacity-50' : '',
      )}
    >
      {props.prefix}
      <input
        id={id}
        key={id}
        type={type}
        ref={ref}
        className={cn(
          'w-full disabled:cursor-not-allowed disabled:bg-transparent',
          variant === 'underline' && 'bg-transparent',
        )}
        maxLength={255}
        tabIndex={0}
        {...props}
      />
      {props.suffix}
    </div>
  );
});

export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(({ className, variant, ...props }, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Input
      ref={ref}
      className={className}
      type={showPassword ? 'text' : 'password'}
      variant={variant}
      {...props}
      suffix={
        <button
          type="button"
          className={cn('focus:outline-none', variant === 'underline' && 'pr-0')}
          onClick={() => setShowPassword(!showPassword)}
          aria-label={showPassword ? 'Hide password' : 'Show password'}
        >
          {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
        </button>
      }
    />
  );
});

const InputNumber = React.forwardRef<
  HTMLInputElement,
  InputProps & {
    decimals?: number;
  }
>(({ decimals = 2, ...props }, ref) => {
  const moneyFormatter = Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    currencyDisplay: 'symbol',
    currencySign: 'standard',
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  const initialValue = props.value ? moneyFormatter.format(Number(props.value)).replaceAll('R$', '').trim() : '';

  const [value, setValue] = React.useReducer((_: any, next: string) => {
    if (Number.isNaN(Number(next))) return '';
    const digits = typeof !next.includes(',')
      ? Number(next.replace(/\D^[.]/g, ''))
          .toFixed(decimals)
          .replace(/\D/g, '')
      : next.replace(/\D/g, '');
    return moneyFormatter
      .format(Number(digits) / 10 ** decimals)
      .replaceAll('R$', '')
      .trim();
  }, initialValue);

  function handleChange(realChangeFn: any, formattedValue: string) {
    const digits = formattedValue.replace(/\D/g, '');
    const realValue = Number(digits) / 10 ** decimals;
    realChangeFn(realValue);
  }

  React.useEffect(() => {
    if (Number(props.value) !== Number(value.replace(/\D,/g, '').replaceAll(',', '.'))) {
      setValue(props.value?.toString() ?? Number('0').toFixed(decimals).toString());
    }
  }, [props.value]);

  return (
    <Input
      maxLength={18}
      {...props}
      ref={ref}
      value={value}
      tabIndex={0}
      onChange={e => {
        const value = e.target.value.includes(',')
          ? e.target.value.replace(/\D/g, '').replace(/(\d{1,})(\d{2})/, '$1,$2')
          : e.target.value;
        if (Number(value.replace(',', '.')) === 0 || Number(Number(value.replace(',', '.'))) > Number(props.max)) {
          setValue(props.max?.toString() ?? '');
          handleChange(props.onChange, Number(props.max).toFixed(decimals).toString());
          return;
        }
        // setValue(e.target.value.replaceAll('R$', '').trim());
        handleChange(props.onChange, value);
      }}
      onKeyDown={e => {
        if (
          !/^\d$/.test(e.key) &&
          e.key !== 'Backspace' &&
          e.key !== 'Delete' &&
          e.key !== 'ArrowLeft' &&
          e.key !== 'ArrowRight' &&
          e.key !== 'Tab' &&
          e.key !== 'Shift' &&
          !(e.ctrlKey && ['c', 'v', 'x', 'z', 'a'].includes(e.key.toLowerCase()))
        ) {
          e.preventDefault();
        }
      }}
    />
  );
});
InputNumber.displayName = 'InputNumber';

const InputMoney = React.forwardRef<HTMLInputElement, InputProps & { currency?: string }>(
  ({ currency = 'BRL', ...props }, ref) => {
    const moneyFormatter = Intl.NumberFormat('pt-BR', {
      currency,
      currencyDisplay: 'symbol',
      currencySign: 'standard',
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const initialValue = props.value ? moneyFormatter.format(Number(props.value)) : '';

    const [value, setValue] = React.useReducer((_: any, next: string) => {
      if (Number.isNaN(Number(next))) return '';

      const digits = !next.includes(currency ?? '')
        ? Number(next.replace(/\D^[.]/g, ''))
            .toFixed(2)
            .replace(/\D/g, '')
        : next.replace(/\D/g, '');
      return moneyFormatter.format(Number(digits) / 100);
    }, initialValue);

    function handleChange(realChangeFn: any, formattedValue: string) {
      const digits = formattedValue.replace(/\D/g, '');
      const realValue = Number(digits) / 100;
      realChangeFn(realValue);
    }

    React.useEffect(() => {
      if (Number(props.value) !== Number(value.replace(/\D,/g, '').replaceAll(',', '.')))
        setValue(props.value?.toString() ?? Number('0').toFixed(2).toString());
    }, [props.value]);

    return (
      <Input
        maxLength={18}
        {...props}
        ref={ref}
        value={value}
        onKeyDown={e => {
          if (
            !/^\d$/.test(e.key) &&
            e.key !== 'Backspace' &&
            e.key !== 'Delete' &&
            e.key !== 'ArrowLeft' &&
            e.key !== 'ArrowRight' &&
            e.key !== 'Tab' &&
            e.key !== 'Shift' &&
            !(e.ctrlKey && ['c', 'v', 'x', 'z', 'a'].includes(e.key.toLowerCase()))
          ) {
            e.preventDefault();
          }
        }}
        onChange={e => {
          const value = e.target.value.includes(',')
            ? e.target.value.replace(/\D/g, '').replace(/(\d{1,})(\d{2})/, '$1,$2')
            : e.target.value;
          if (Number(value.replace(',', '.')) === 0 || Number(Number(value.replace(',', '.'))) > Number(props.max)) {
            setValue(props.max?.toString() ?? '');
            handleChange(props.onChange, Number(props.max).toFixed(2).toString());
            return;
          }
          // setValue(e.target.value.replaceAll('R$', '').trim());
          handleChange(props.onChange, value);
        }}
      />
    );
  },
);
InputMoney.displayName = 'InputMoney';

const InputWithMask = React.forwardRef<HTMLInputElement, InputProps & { mask: string }>(
  ({ className, mask, ...props }, ref) => {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const id = React.useMemo(() => uuid(), []);

    React.useEffect(() => {
      if (inputRef.current) {
        const im = new InputMask(mask);
        im.mask(inputRef.current);
      }
    }, [mask]);

    return (
      <Input
        key={id + mask}
        ref={el => {
          if (ref && typeof ref === 'function') ref(el);
          else (ref as any).current = el;
          if (inputRef) inputRef.current = el;
        }}
        className={className}
        {...props}
      />
    );
  },
);

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        'focus flex w-full rounded-md border border-gray-200 bg-background px-3 py-2 text-sm outline-none transition-all file:bg-transparent file:text-sm file:font-normal placeholder:text-muted-foreground focus-within:border-primary/40 focus-within:shadow-[0px_0px_3px_3px_#ffa13c1A] disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = 'Input';

export { Input, TextArea, InputNumber, InputWithMask, InputMoney };
