import React, { createContext, useContext, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DTOCacheCreditRequest from '../@types/dtos/credit-request/DTOCacheCreditRequest';
import DTOCreditOperation from '../@types/dtos/credit-request/DTOCreditOperation';
import DTOCreditPhase from '../@types/dtos/credit-request/DTOCreditPhase';
import DTOCreditSubDivision from '../@types/dtos/credit-request/DTOCreditSubDivision';
import DTOStatus from '../@types/dtos/credit-request/DTOStatus';
import DTOTax from '../@types/dtos/credit-request/DTOTax';
import DTOTeam from '../@types/dtos/credit-request/DTOTeam';
import DTOErrorReponse from '../@types/dtos/DTOErrorReponse';
import DTOSubsidiaryModel from '../@types/dtos/location/DTOSubsidiaryModel';
import DTOCacheBank from '../@types/dtos/person/DTOCacheBank';
import DTOCachePerson from '../@types/dtos/person/DTOCachePerson';
import DTOLegalStatus from '../@types/dtos/person/DTOLegalStatus';
import DTOPhysicalStatus from '../@types/dtos/person/DTOPhysicalStatus';
import { TEAM_STATUS_NOT_SYNCED_SLUG } from '../configs/constants';
import { SESSION_CREDIT_CACHE, SESSION_PERSON_CACHE } from '../configs/storage';
import cacheService from '../services/api/cache';
import { getTranslations } from '../services/translations';
import TeamStatusData from '../types/CreditRequest/DTOTeamStatus';
import EntityModelData from '../types/EntityModelData';
import CacheState, {
  CacheCreditIndexDataParam,
  CachePersonIndexDataParam,
  CacheOptions,
  CacheContextData,
  CacheCreditStatusIndexDataParam,
} from '../types/Hooks/cache';
import DTOCityModel from '../types/Location/DTOCityModel';
import DTOStateModel from '../types/Location/DTOStateModel';
import UserData from '../types/User/UserData';
import { usePage } from './page';

const CacheContext = createContext<CacheContextData>({} as CacheContextData);

const CacheProvider: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation();

  const { alertStatus } = usePage();

  const [data, setData] = useState<CacheState>(() => {
    const creditDataString = localStorage.getItem(SESSION_CREDIT_CACHE);
    const personDataString = localStorage.getItem(SESSION_PERSON_CACHE);
    const creditData = creditDataString && creditDataString !== 'undefined' ? JSON.parse(creditDataString) : {};
    const personData = personDataString && personDataString !== 'undefined' ? JSON.parse(personDataString) : {};
    if (creditData && personData) {
      return {
        creditData,
        personData,
        ...creditData,
        ...personData,
      };
    }

    return {} as CacheState;
  });

  const updateCache = useCallback(async (option = null) => {
    let updatedData = {};
    if (option === 'person' || option === null) {
      await cacheService
        .personData()
        .then((response: { data: DTOCachePerson }) => {
          const dataPersonData = response.data;

          localStorage.setItem(SESSION_PERSON_CACHE, JSON.stringify(dataPersonData));

          updatedData = {
            ...updatedData,
            personData: dataPersonData,
            ...dataPersonData,
          };
        })
        .catch((err: DTOErrorReponse) => {
          alertStatus(err, 'error');
        });
    }

    if (option === 'credit' || option === null) {
      await cacheService
        .creditData()
        .then((response: { data: DTOCacheCreditRequest }) => {
          const dataCreditRequest = response.data;

          localStorage.setItem(SESSION_CREDIT_CACHE, JSON.stringify(dataCreditRequest));

          updatedData = {
            ...updatedData,
            creditData: dataCreditRequest,
            ...dataCreditRequest,
          };
        })
        .catch(err => {
          alertStatus(err, 'error');
        });
    }

    setData(dataState => ({
      ...dataState,
      ...updatedData,
    }));
    // eslint-disable-next-line
  }, []);

  const addItemCache = useCallback((item: string, value: any) => {
    localStorage.setItem(item, JSON.stringify(value));
    // eslint-disable-next-line
  }, []);

  const updateItemCache = useCallback((item: string, value: any) => {
    localStorage.setItem(item, JSON.stringify(value));
    // eslint-disable-next-line
  }, []);

  const removeItemCache = useCallback((item: string) => {
    localStorage.removeItem(item);
    // eslint-disable-next-line
  }, []);

  const getItemCache = useCallback((item: string) => {
    return JSON.parse(localStorage.getItem(item) ?? '{}');
    // eslint-disable-next-line
  }, []);

  const getOptions = useCallback(
    (indexData: any): CacheOptions[] => {
      const tmpData: any = data;
      if (!tmpData[indexData]) {
        return [];
      }

      if (!tmpData || !tmpData[indexData] || tmpData[indexData].length === 0) {
        return [];
      }

      return tmpData[indexData].map((i: any) => ({
        key: i.id.toString(),
        value: i.id.toString(),
        label: i.name.toString(),
      }));
    },
    // eslint-disable-next-line
    [data],
  );

  const getPersonOptions = useCallback(
    (indexData: CachePersonIndexDataParam): CacheOptions[] => {
      const { personData } = data;

      if (!personData || !personData[indexData] || personData[indexData].length === 0) {
        return [];
      }
      switch (indexData) {
        case 'person_stage': {
          const options: any[] = personData[indexData];

          return options.map((i: DTOLegalStatus | DTOPhysicalStatus) => ({
            key: i.id,
            label: getTranslations(i, 'title', i18n.language, 'name'),
            value: i.id,
            slug: i.slug,
          }));
        }
        case 'bank':
          return personData[indexData].map((i: DTOCacheBank) => ({
            key: i.id.toString(),
            value: i.id.toString(),
            label: `${i.code} - ${i.name}`,
          }));
        default:
          return personData[indexData].map((i: EntityModelData) => ({
            key: i.id.toString(),
            value: i.id.toString(),
            label: i.name.toString(),
            slug: i.slug ? i.slug.toString() : '',
            tag: i.tag ? i.tag.toString() : '',
          }));
      }
    },
    // eslint-disable-next-line
    [data],
  );

  const getCreditOptions = useCallback(
    (indexData: CacheCreditIndexDataParam): CacheOptions[] => {
      const { creditData } = data;

      if (!creditData || !creditData[indexData] || creditData[indexData].length === 0) {
        return [];
      }

      switch (indexData) {
        case 'credit_status':
        case 'team':
        case 'team_credit_status':
        case 'team_credit_status_phase':
        case 'operation': {
          const options: (DTOStatus | DTOTeam | TeamStatusData | DTOCreditPhase | DTOCreditOperation)[] =
            creditData[indexData];

          return options
            .filter(
              (i: DTOStatus | DTOTeam | TeamStatusData | DTOCreditPhase | DTOCreditOperation) =>
                i.slug !== TEAM_STATUS_NOT_SYNCED_SLUG,
            )
            .map((i: DTOStatus | DTOTeam | TeamStatusData | DTOCreditPhase | DTOCreditOperation) => ({
              key: i.id ?? '',
              label: getTranslations(i, 'title', i18n.language, 'name'),
              value: i.id ?? '',
              slug: i.slug ?? '',
              tag: i.tag ?? '',
              finished_status: 'finished_status' in i ? i?.finished_status : [],
            }));
        }
        case 'sub_division': {
          const options: DTOCreditSubDivision[] = creditData[indexData];

          return options.map((i: DTOCreditSubDivision) => ({
            key: i.id.toString(),
            label: t(`geral.subdivision.${i.slug}`),
            value: i.id.toString(),
            slug: i.slug.toString(),
            datasul_name: i.datasul_name,
            datasul_code: i.datasul_code,
          }));
        }
        case 'state': {
          const options: DTOStateModel[] = creditData[indexData];

          return options.map((i: DTOStateModel) => ({
            key: i.id.toString(),
            label: i.name.toString(),
            value: i.id.toString(),
            slug: i.code.toString(),
          }));
        }
        case 'city':
        case 'user': {
          const options: (DTOCityModel | UserData)[] = creditData[indexData];

          return options.map((i: DTOCityModel | UserData) => ({
            key: i.id.toString(),
            label: i.name.toString(),
            value: i.id.toString(),
          }));
        }
        case 'subsidiary': {
          const options: DTOSubsidiaryModel[] = creditData[indexData];

          return options.map((i: DTOSubsidiaryModel) => ({
            key: i.id.toString(),
            label: i.name.toString(),
            value: i.id.toString(),
            parent_id: i.region_id ? i.region_id.toString() : '',
            region_id: i.region_id ? i.region_id.toString() : '',
          }));
        }
        case 'tax': {
          return creditData[indexData].map((i: DTOTax) => ({
            key: i.id.toString(),
            label: i.name.toString(),
            value: i.id.toString(),
            description: i.description.toString(),
            tag: i.tag.toString(),
            tax: i.value.toString(),
          }));
        }
        default: {
          return creditData[indexData].map((i: EntityModelData) => ({
            key: i.id.toString(),
            label: i.name.toString(),
            value: i.id.toString(),
            slug: i.slug ? i.slug.toString() : '',
            tag: i.tag ? i.tag.toString() : '',
          }));
        }
      }
    },
    // eslint-disable-next-line
    [data, getTranslations, i18n],
  );

  const getCreditStatusOptions = useCallback(
    (indexData: CacheCreditStatusIndexDataParam, excludeStatusWithoutTeam = false): CacheOptions[] => {
      const { creditData } = data;

      if (!creditData || !creditData[indexData] || creditData[indexData].length === 0) {
        return [];
      }

      let options: (DTOStatus | DTOTeam | TeamStatusData)[] = creditData[indexData];

      if (excludeStatusWithoutTeam === true && indexData === 'team_credit_status') {
        const teamCreditStatusOptions: TeamStatusData[] = creditData[indexData];
        options = teamCreditStatusOptions.filter(option => !!option?.team_id);
        return options.map((option: DTOStatus | DTOTeam | TeamStatusData) => ({
          key: option.id ?? '',
          label: getTranslations(option, 'title', i18n.language, 'name'),
          value: option.id ?? '',
          slug: option.slug ?? '',
        }));
      }

      if (excludeStatusWithoutTeam === true && indexData === 'credit_status') {
        const creditStatusOptions: DTOStatus[] = creditData[indexData];
        options = creditStatusOptions.filter(option => option.slug !== TEAM_STATUS_NOT_SYNCED_SLUG);
        return options.map((option: DTOStatus | DTOTeam | TeamStatusData) => ({
          key: option.id ?? '',
          label: getTranslations(option, 'title', i18n.language, 'name'),
          value: option.id ?? '',
          slug: option.slug ?? '',
        }));
      }

      return options.map((option: DTOStatus | DTOTeam | TeamStatusData) => ({
        key: option.id ?? '',
        label: getTranslations(option, 'title', i18n.language, 'name'),
        value: option.id ?? '',
        slug: option.slug ?? '',
      }));
    },
    // eslint-disable-next-line
    [data, getTranslations, i18n],
  );

  const getCityByStateOptions = useCallback(
    (state_id: string): CacheOptions[] => {
      const { creditData } = data;

      if (!creditData) return [];

      if (!creditData?.state?.length) {
        return [];
      }

      if (!creditData?.city?.length) {
        return [];
      }

      const citiesFiltered = creditData.city.filter((c: DTOCityModel) => c.state_id === state_id);
      return citiesFiltered.map((i: EntityModelData) => ({
        key: i.id.toString(),
        value: i.id.toString(),
        label: i.name.toString(),
      }));
    },
    // eslint-disable-next-line
    [data],
  );

  return (
    <CacheContext.Provider
      value={{
        creditData: data.creditData,
        personData: data.personData,
        updateCache,
        addItemCache,
        updateItemCache,
        removeItemCache,
        getItemCache,
        getCreditOptions,
        getCreditStatusOptions,
        getPersonOptions,
        getCityByStateOptions,
        getOptions,
        business_unit: data.business_unit || [],
        bank: data.bank || [],
        city: data.city || [],
        contract_type: data.contract_type || [],
        credit_status: data.credit_status || [],
        current_harvest: data.current_harvest || '',
        currency_type: data.currency_type || [],
        division: data.division || [],
        harvest: data.harvest || [],
        incoterm: data.incoterm || [],
        modality: data.modality || [],
        operation: data.operation || [],
        position: data.position || [],
        product: data.product || [],
        reason: data.reason || [],
        region: data.region || [],
        state: data.state || [],
        sub_division: data.sub_division || [],
        subsidiary: data.subsidiary || [],
        tax: data.tax || [],
        team: data.team || [],
        team_credit_status: data.team_credit_status || [],
        team_credit_status_phase: data.team_credit_status_phase || [],
        user: data.user || [],
        warranty_pledge_area: data.warranty_pledge_area || [],
        warranty_type: data.warranty_type || [],
        activity_diversification: data.activity_diversification || [],
        communion_regime: data.communion_regime || [],
        credit_history: data.credit_history || [],
        culture: data.culture || [],
        managerial_skill: data.managerial_skill || [],
        marital_status: data.marital_status || [],
        productivity: data.productivity || [],
        reference_square: data.reference_square || [],
        person_stage: data.person_stage || [],
        structure_conservation: data.structure_conservation || [],
        suitability: data.suitability || [],
        phase: data.phase || [],
        technological_level: data.technological_level || [],
        economic_group_type: data.economic_group_type || [],
        document_type_credit: data.document_type_credit || [],
        document_type_person: data.document_type_person || [],
        weight_type: data.weight_type || [],
      }}
    >
      {children}
    </CacheContext.Provider>
  );
};

function useCache(): CacheContextData {
  return useContext(CacheContext);
}

export { CacheProvider, useCache };
