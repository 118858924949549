import React from 'react';

import UserData from '@/types/User/UserData';

import { useGetUserSubdivisionsQuery } from '../../api/use-get-user-subdivions.query';
import { UserPermissionsTable } from './user-permissions.table';

type Props = {
  user?: UserData | null;
};

export const UserPermissionsSubdivisionsTable = ({ user }: Props) => {
  return <UserPermissionsTable query={useGetUserSubdivisionsQuery} user={user} />;
};
