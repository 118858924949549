import api from '@/services/api/api';
import { useQuery } from '@tanstack/react-query';

interface GetUserConfigDTO {
  app: {
    locale: string;
    sidebar: {
      isMini: boolean;
    };
    language: string;
  };
  modules: {
    credit: {
      kanban: {
        legal: {
          hiddenColumns: string[];
          expandedColumns: string[];
          allColumnsExpanded: boolean;
          allColumnsMinimized: boolean;
        };
        credit: {
          hiddenColumns: string[];
          expandedColumns: string[];
          allColumnsExpanded: boolean;
          allColumnsMinimized: boolean;
        };
        prefin: {
          hiddenColumns: string[];
          expandedColumns: string[];
          allColumnsExpanded: boolean;
          allColumnsMinimized: boolean;
        };
        commercial: {
          hiddenColumns: string[];
          expandedColumns: string[];
          allColumnsExpanded: boolean;
          allColumnsMinimized: boolean;
        };
      };
      filters: {
        team: null | string;
      };
      pageView: {
        credit: string;
      };
    };
    person: {
      kanban: {
        legal: {
          hiddenColumns: string[];
          expandedColumns: string[];
          allColumnsExpanded: boolean;
          allColumnsMinimized: boolean;
        };
        physical: {
          hiddenColumns: string[];
          expandedColumns: string[];
          allColumnsExpanded: boolean;
          allColumnsMinimized: boolean;
        };
      };
      filters: {
        team: null | string;
      };
      pageView: {
        legal: string;
        physical: string;
      };
    };
  };
}

export const useGetUserConfig = (id: string) => {
  return useQuery({
    queryKey: [`admin/user/${id}/config`, id],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
    queryFn: async () => {
      const response = await api.get<GetUserConfigDTO>(`admin/user/${id}/config`);
      return response.data as GetUserConfigDTO;
    },
  });
};
