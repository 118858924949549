import * as React from 'react';

import { cn } from '@/lib/utils';
import * as SwitchPrimitives from '@radix-ui/react-switch';

const switchVariants = {
  size: {
    default: 'h-6 w-11',
    sm: 'h-5 w-9',
  },
  thumb: {
    default: 'h-5 w-5 data-[state=checked]:translate-x-5',
    sm: 'h-4 w-4 data-[state=checked]:translate-x-4',
  },
};

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    onChange?: (_event: any) => void;
    onValueChange?: (_value: boolean) => void;
    value?: boolean;
    size?: keyof typeof switchVariants.size;
  }
>(({ className, value, name, onChange, onValueChange, size = 'default', ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
      switchVariants.size[size],
      className,
    )}
    name={name}
    checked={value}
    onCheckedChange={isChecked => {
      onValueChange?.(isChecked);
      console.log('changted', isChecked);
      onChange?.({
        target: {
          value: isChecked,
          name,
        },
      });
    }}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'shadow-lg pointer-events-none block rounded-full bg-background ring-0 transition-transform data-[state=unchecked]:translate-x-0',
        switchVariants.thumb[size],
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
