import React from 'react';

import UserData from '@/types/User/UserData';

import { useGetUserStatusQuery } from '../../api/use-get-user-status.query';
import { UserPermissionsTable } from './user-permissions.table';

type Props = {
  user?: UserData | null;
};

export const UserPermissionsStatusTable = ({ user }: Props) => {
  return <UserPermissionsTable query={useGetUserStatusQuery} user={user} />;
};
