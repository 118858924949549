// import { AxiosRequestConfig } from 'axios';

import api from './api';

export default {
  login: async (params: object = null): Promise<any> => {
    return api.post('auth/login', params);
  },

  validateEmail: async (id: string, hash: string, params: object = null): Promise<any> => {
    return api.get(`auth/email/verify/${id}/${hash}`, params);
  },

  microsoftLogin: async (params: object = null): Promise<any> => {
    return api.post('auth/microsoft', params);
  },

  getUserData: async (): Promise<any> => {
    return api.get('auth/me');
  },

  logout: async (params: object = null): Promise<any> => {
    return api.get('auth/logout', params);
  },

  recoverPassword: async (params: object = null): Promise<any> => {
    return api.post('auth/change-password', params);
  },

  forgotPassword: async (params: object = null): Promise<any> => {
    return api.post('auth/forgot-password', params);
  },
  updateExpiredPassword: async (params: object = null): Promise<any> => {
    return api.post('auth/password/post-expired', params);
  },
  passwordLastChange: async (params: object = null): Promise<any> => {
    return api.get('auth/password/last-change', params);
  },

  twoFactorAuthentication: {
    getQrCode: async (params: object = null): Promise<any> => {
      return api.get('auth/two-factor-authentication/qr-code', params);
    },
    checkEnabled: async (params: object = null): Promise<any> => {
      return api.get(`auth/two-factor-authentication/check`, params);
    },
    sendChallengeCode: async (params: object = null): Promise<any> => {
      return api.post('auth/two-factor-authentication/challenge', params);
    },
    enable: async (params: object = null): Promise<any> => {
      return api.post('auth/two-factor-authentication', params);
    },
  },
};
