import api from '@/services/api/api';
import { UserConfig } from '@/types/User/UserData';
import { useMutation } from '@tanstack/react-query';

type Props = {
  id: string;
  config: UserConfig;
  params?: object;
};

export const useUserConfigMutation = () => {
  return useMutation({
    mutationFn: async ({ id, config, params }: Props) => {
      return api.post(`admin/user/${id}/config`, { config, locale: config.app.language, _method: 'PATCH' }, params);
    },
  });
};
