import { useMemo } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

type Props<T> = {
  initialValues: Record<keyof T, string | string[] | number | number[]>;
};

export const objectToSeachParams = (obj: Record<string, string | string[] | number | number[]>) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => value !== '' && (value as Array<any>)?.length > 0)
      .map(([key, value]) => [key, Array.isArray(value) ? value.join(',') : value]),
  ) as URLSearchParamsInit;
};

export const searchParamsToObject = (searchParams: Record<string, string>, arrayFields: string[]) => {
  return Object.fromEntries(
    Object.entries(searchParams)
      .filter(([_, value]) => !!value)
      .map(([key, value]) => [key, arrayFields.includes(key) ? value.split(',') : value]),
  );
};

export const useQueryStringFilter = <T extends Record<string, string | string[] | number | number[]>>({
  initialValues,
}: Props<T>) => {
  const [searchParams, setSearchParams] = useSearchParams((initialValues ?? {}) as URLSearchParamsInit);

  const setFilter = (filter: T) => setSearchParams(objectToSeachParams(filter) as URLSearchParamsInit);

  const arrayFields = Object.keys(initialValues ?? {}).filter(key => Array.isArray(initialValues?.[key]));

  const filter = useMemo(() => {
    const entries = Object.fromEntries(searchParams.entries());
    // extract array keys from type T
    const removeEmptyFieldsFromEntries = searchParamsToObject(entries, arrayFields);
    return removeEmptyFieldsFromEntries as T;
  }, [searchParams]);

  const filterWithoutEmptyValues = useMemo(() => {
    return Object.fromEntries(
      Object.entries(filter).filter(([_, value]) => value !== '' && (value as Array<any>)?.length > 0),
    );
  }, [filter]);

  return {
    setFilter,
    filter,
    searchParams,
    filterWithoutEmptyValues,
  };
};
