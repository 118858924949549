import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider, Spin as AntSpin } from 'antd';
import ptBRLocale from 'antd/es/locale/pt_BR';
import './services/i18next';
import './assets/css/index.css';
import { Toaster } from '@/components/ui/toaster';
import { TooltipProvider } from '@/components/ui/tooltip';
import { AxiosError } from 'axios';
import { ValidateMessages } from 'rc-field-form/lib/interface';
import { ThemeProvider } from 'styled-components';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useAlertDialog } from '@/components/ui/alert-dialog';

import { Spin } from './components';
import AppProviders from './hooks/index';
import Routes from './routes';
import { messages } from './services/messages';
import GlobalStyle from './styles/global';
import theme from './styles/theme';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/cofco.*\.agrodata\.agr\.br\//],
  environment: import.meta.env.VITE_ENVIRONMENT,
  enabled: import.meta.env.VITE_ENVIRONMENT !== 'local',
});

const App: React.FC = () => {
  AntSpin.setDefaultIndicator(Spin);
  const { alert } = useAlertDialog();
  const { t } = useTranslation();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: error => {
            // check error is instanceof AxiosError
            Sentry.captureException(error);

            if (error instanceof AxiosError) {
              if (error?.response?.data?.message)
                return alert({
                  title: t('error'),
                  description: error.response.data.message,
                  type: 'error',
                });

              return alert({
                title: t('error'),
                description: error.message,
                type: 'error',
              });
            }

            alert({
              title: t('error'),
              description: error.message,
              type: 'error',
            });
          },
        }),
        mutationCache: new MutationCache({
          onError: error => {
            // Log error to Sentry
            Sentry.captureException(error);

            if (error instanceof AxiosError) {
              if (typeof error.response === 'object') {
                if ('data' in error.response) {
                  if (typeof error.response.data === 'string') {
                    return alert({
                      title: t('error'),
                      description: error.response.data,
                      type: 'error',
                    });
                  }
                  if ('message' in error.response.data) {
                    return alert({
                      title: t('error'),
                      description: error.response.data.message,
                      type: 'error',
                    });
                  }
                }
              }

              return alert({
                title: t('error'),
                description: error.message,
                type: 'error',
              });
            }

            alert({
              title: t('error'),
              description: error.message,
              type: 'error',
            });
          },
        }),
      }),
    [],
  );

  const { i18n } = useTranslation();
  const validateMessages: ValidateMessages = messages(i18n.language);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <TooltipProvider>
          <QueryClientProvider client={queryClient}>
            <ConfigProvider form={{ validateMessages }} locale={ptBRLocale}>
              <AppProviders>
                <Routes />
              </AppProviders>
            </ConfigProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
          <GlobalStyle />
        </TooltipProvider>
      </ThemeProvider>
      <Toaster />
    </Router>
  );
};

export default App;
