import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { v4 as uuid } from 'uuid';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function randomUUID() {
  return uuid();
}

export const UrlFilterUtils = {
  extractExistingParams(searchParams: URLSearchParams, notArrayKeys: string[] = []) {
    const entries = Array.from(searchParams.entries());
    return entries.reduce(
      (acc, a) => {
        if (notArrayKeys)
          if (notArrayKeys.includes(a[0])) {
            const [_, v] = a;
            acc[a[0]] = v;
            return acc;
          }

        (acc[a[0]] = acc[a[0]] || []).push(a[1]);
        return acc;
      },
      {} as Record<string, any>,
    );
  },
  removeExistingParamsArrayValue(searchParams: URLSearchParams, key: string, value: string) {
    const existingParams = UrlFilterUtils.extractExistingParams(searchParams);
    if (existingParams[key]) {
      existingParams[key] = existingParams[key].filter((v: string) => v !== value);
    }
    if (existingParams[key].length === 0) {
      delete existingParams[key];
    }
    return existingParams;
  },
  augmentExistingParamsArrayValue(searchParams: URLSearchParams, key: string, value: string) {
    const existingParams = UrlFilterUtils.extractExistingParams(searchParams);
    existingParams[key] = existingParams[key] || [];
    existingParams[key].push(value);
    return existingParams;
  },
  updateParams(searchParams: URLSearchParams, params: Record<string, any>) {
    Object.entries(params).forEach(([key, value]) => {
      if (!value) return searchParams.delete(key);
      if (Array.isArray(value)) {
        if (!value.length) return searchParams.delete(key);
        value.forEach(v => {
          searchParams.append(key, v);
        });
      } else {
        searchParams.set(key, value);
      }
    });

    return searchParams;
  },
};

export const ArrayUtils = {
  isPropValuesEqual: (subject: any, target: any, propNames: string[]) =>
    propNames.every(propName => subject[propName] === target[propName]),
  getUniqueItemsByProperties: (items: any[], propNames: string[]) => {
    const propNamesArray = Array.from(propNames);

    return items
      .filter(i => !!i)
      .filter(
        (item, index, array) =>
          index === array.findIndex(foundItem => ArrayUtils.isPropValuesEqual(foundItem, item, propNamesArray)),
      );
  },
};

export const JsonUtils = {
  extractKeyValue: <T>(key: keyof T, json: Record<keyof T, unknown>): unknown => {
    if (!json) return null;
    const [currentKey, ...rest] = key.toString().split('.');
    if (rest.length > 0)
      return JsonUtils.extractKeyValue(rest.join('.'), json[currentKey as keyof T] as Record<string, unknown>);

    return json[key];
  },
};
