import api from './api';

export default {
  provider: {
    /**
     * Returns the provider data from the datasul/sap.
     *
     *
     * @param params - {docNumber: string, ...}
     * @param sapFirst - If true, the search will be done in SAP first and use the datasul search as a fallback, if not, it will be done only in datasul.
     * @returns the provider data from the datasul/sap.
     *
     */
    search: async (params: object = null, sapFirst = false): Promise<any> => {
      return sapFirst ? api.get(`datasul-sap/provider`, params) : api.get(`datasul/provider`, params);
    },
  },
  contract: {
    search: async (params: object = null): Promise<any> => {
      return api.get(`datasul/contract`, params);
    },
  },
  contractV2: {
    search: async (params: object = null): Promise<any> => {
      return api.get(`datasul/contract/v2`, params);
    },
    force: async (params: object = null): Promise<any> => {
      return api.put(`contract/datasul/force-update-by-number`, params);
    },
    forceContractById: async (contractId: string): Promise<any> => {
      return api.put(`contract/${contractId}/force-update`);
    },
  },
  connection: async (abortController?: AbortController): Promise<any> => {
    return api.get(`datasul/status`, { signal: abortController?.signal });
  },
  ptaxFromDate: async (date: string, params = {}): Promise<any> => {
    return api.get(`datasul/ptax/${date}`, params);
  },
};
