import api from '@/services/api/api';
import { NotificationMessage } from '@/types/User/UserNotificationData';
import { useMutation } from '@tanstack/react-query';

interface UpdateNotificationConfigPayload {
  userId: string;
  messages: NotificationMessage[];
  stop_email_notifications: boolean;
  stop_teams_notifications: boolean;
  stop_broadcast_notifications: boolean;
}

export const useUpdateNotificationConfig = () => {
  return useMutation({
    mutationKey: ['updateNotificationConfig'],
    mutationFn: async ({ userId, ...rest }: UpdateNotificationConfigPayload) => {
      const response = await api.put(`admin/user/${userId}/message`, rest);
      return response.data;
    },
  });
};
