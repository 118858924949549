import React, { useCallback, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormItem } from '@/components/ui/form-item';
import { Switch } from '@/components/ui/switch';
import { GroupedNotifications, NotificationItem } from '@/types/User/UserNotificationData';
import { Row } from '@tanstack/react-table';

type NotificationConfigFormColumnsProps = {
  notifications: NotificationItem[];
  messages: { id: string; via_email: boolean; via_broadcast: boolean; via_teams: boolean }[];
  form: UseFormReturn<any>;
};

export interface NotificationRowData extends NotificationItem {
  isGroupHeader?: boolean;
  groupType?: string;
  allSelected?: boolean;
  isSelected?: boolean;
  subRows?: NotificationRowData[];
}

export const userNotificationConfigFormConfig = ({
  notifications,
  messages,
  form,
}: NotificationConfigFormColumnsProps) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const groupNotificationsByType = useCallback((notifications: NotificationItem[]) => {
    if (!notifications) return {};

    return notifications.reduce((acc: GroupedNotifications, notification) => {
      if (!notification?.message_type?.name) return acc;

      const type = notification.message_type.name;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(notification);
      return acc;
    }, {});
  }, []);

  const groupedNotifications = useMemo(() => {
    return groupNotificationsByType(notifications || []);
  }, [notifications]);

  const toggleSection = (type: string) => {
    setExpanded(prev => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const toggleAllSections = () => {
    const newExpandedState = !Object.values(expanded).some(Boolean);
    const groupedData = groupNotificationsByType(notifications || []);

    const newExpanded: Record<string, boolean> = {};
    Object.keys(groupedData).forEach(type => {
      newExpanded[type] = newExpandedState;
    });

    setExpanded(newExpanded);
  };

  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: () => (
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex h-6 w-6 min-w-6 items-center justify-center"
              onClick={toggleAllSections}
            >
              {Object.values(expanded).some(Boolean) ? '+' : '-'}
            </button>
            <span>{t('notification-title')}</span>
          </div>
        ),
        meta: {
          body: {
            cell: {
              className: 'p-0 m-0',
            },
          },
        },
        cell: ({ row }: { row: Row<NotificationRowData> }) => {
          const data = row.original;
          if (data.isGroupHeader) {
            return (
              <div className="relative flex h-full items-center gap-2 bg-gray-50 p-4">
                <div className="absolute inset-0" />
                <div className="relative z-10 flex w-full items-center gap-2">
                  <button
                    type="button"
                    className="flex h-6 w-6 min-w-6 items-center justify-center"
                    onClick={() => toggleSection(data.groupType!)}
                  >
                    {expanded[data.groupType!] ? '+' : '-'}
                  </button>
                  <div className="flex items-center gap-2 font-bold">{t(`${data.groupType}`)}</div>
                </div>
              </div>
            );
          }

          return (
            <div className="flex items-center gap-2 pl-8">
              <span>{data.name['pt-br']}</span>
            </div>
          );
        },
      },
      {
        id: 'via_email',
        header: t('send-email'),
        meta: {
          body: {
            cell: {
              className: 'p-0 m-0',
            },
          },
        },
        cell: ({ row }: { row: Row<NotificationRowData> }) => {
          const data = row.original;
          if (data.isGroupHeader)
            return (
              <div className="relative h-full gap-2 bg-gray-50 p-4">
                <div className="absolute inset-0 bg-gray-50" />
              </div>
            );

          return (
            <div className="p-2 text-center">
              <FormItem name={`messages[${row.index}].via_email`}>
                <Switch checked={form.watch(`messages[${row.index}].via_email`)} />
              </FormItem>
            </div>
          );
        },
      },
      {
        id: 'via_broadcast',
        header: t('send-on-site'),
        meta: {
          body: {
            cell: {
              className: 'p-0 m-0',
            },
          },
        },
        cell: ({ row }: { row: Row<NotificationRowData> }) => {
          const data = row.original;
          if (data.isGroupHeader)
            return (
              <div className="relative h-full bg-gray-50 p-4">
                <div className="absolute inset-0 bg-gray-50" />
              </div>
            );

          return (
            <div className="p-2 text-center">
              <FormItem name={`messages[${row.index}].via_broadcast`}>
                <Switch checked={form.watch(`messages[${row.index}].via_broadcast`)} />
              </FormItem>
            </div>
          );
        },
      },
      {
        id: 'via_teams',
        header: t('send-to-teams'),
        meta: {
          body: {
            cell: {
              className: 'p-0 m-0',
            },
          },
        },
        cell: ({ row }: { row: Row<NotificationRowData> }) => {
          const data = row.original;
          if (data.isGroupHeader)
            return (
              <div className="relative h-full bg-gray-50 p-4">
                <div className="absolute inset-0 bg-gray-50" />
              </div>
            );

          return (
            <div className="p-2 text-center">
              <FormItem name={`messages[${row.index}].via_teams`}>
                <Switch checked={form.watch(`messages[${row.index}].via_teams`)} />
              </FormItem>
            </div>
          );
        },
      },
    ],
    [messages, form.formState.dirtyFields, expanded],
  );

  return { columns, expanded, groupedNotifications };
};
