import React from 'react';

import UserData from '@/types/User/UserData';

import { useGetUserTeamsQuery } from '../../api/use-get-user-teams.query';
import { UserPermissionsTable } from './user-permissions.table';

type Props = {
  user?: UserData | null;
};

export const UserPermissionsTeamsTable = ({ user }: Props) => {
  return <UserPermissionsTable query={useGetUserTeamsQuery} user={user} />;
};
