import React, { createContext, useCallback, useContext, useState } from 'react';

import SidebarData from '../@types/hooks/sidebar';
import { SidebarConfig, sidebarConfigDefault } from '../types/User/UserData';
import { useAuth } from './auth';

const SidebarContext = createContext<SidebarData>({} as SidebarData);

const SidebarProvider: React.FC = ({ children }) => {
  const { user, updateUserConfig, userConfig } = useAuth();

  const [isChanging, setIsChanging] = useState(false);
  const [isMini, setIsMini] = useState<boolean>(() => {
    if (user && userConfig) {
      const configs = userConfig.app?.sidebar;
      if (!configs) {
        const sidebarConfig: SidebarConfig = sidebarConfigDefault;
        return sidebarConfig.isMini;
      }

      return configs.isMini;
    }
    return sidebarConfigDefault.isMini;
  });

  const toggle = useCallback(() => {
    setIsChanging(true);
    const updateConfigs: SidebarConfig = userConfig.app.sidebar;
    setIsMini(!updateConfigs.isMini);
    userConfig.app.sidebar.isMini = !updateConfigs.isMini;
    updateUserConfig(userConfig);

    setIsChanging(false);
  }, [updateUserConfig, user]);

  return (
    <SidebarContext.Provider
      value={{
        isChanging,
        isMini,
        toggle,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

function useSidebar() {
  return useContext(SidebarContext);
}

export { SidebarProvider, useSidebar };
