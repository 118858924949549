import React, { useEffect } from 'react';

import { SESSION_USER_TOKEN } from '@/configs/storage';
import { useAuth } from '@/hooks/auth';

export const SilentLogin = () => {
  const { tokenResponse } = useAuth();
  // get token from url  /silent-login?token=
  const token = new URLSearchParams(window.location.search).get('token');
  const expires_in = new URLSearchParams(window.location.search).get('expires_in');

  const loadUserData = async () => {
    if (token) {
      localStorage.setItem(SESSION_USER_TOKEN, token);
      setTimeout(async () => {
        await tokenResponse({ data: { token, expires_in, token_type: 'bearer' } }, '/', false, true);
      }, 1000);
    }
  };

  useEffect(() => {
    loadUserData();
  }, [token]);

  return <div>Loading...</div>;
};
