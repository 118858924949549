import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Loading from '@/components/ui/loading';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useQueryStringFilter } from '@/hooks/helpers/use-query-string-filter';

import { useGetUserQuery } from './api/use-get-user.query';
import { AuthTab } from './user-auth.form';
import { NotificationConfigTab } from './user-notification-config.form';
import { UserPermissionsTab } from './user-permissions/user-permissions';
import { UserDetailsTab } from './user.form';

export type PageParams = {
  id: string;
};

type TabValues = 'details' | 'auth' | 'permissions' | 'notification';

export const User = () => {
  const { t } = useTranslation();
  const { setFilter, filter } = useQueryStringFilter<{ tab: TabValues }>({
    initialValues: {
      tab: 'details',
    },
  });

  const params = useParams<PageParams>();
  const { id: idParam } = params;

  const getUserQuery = useGetUserQuery(idParam && idParam !== 'new' ? idParam : '');

  return (
    <Tabs
      defaultValue={filter.tab}
      className="flex h-full flex-col overflow-hidden bg-white"
      onValueChange={value =>
        setFilter({
          tab: value as TabValues,
        })
      }
    >
      <TabsList className="w-full pt-2">
        <TabsTrigger value="details" title={t('pages.credit-request.tabs.general-data')}>
          {t('pages.credit-request.tabs.general-data')}
        </TabsTrigger>
        {idParam !== 'new' && (
          <>
            <TabsTrigger value="auth" title={t('auth')}>
              {t('auth')}
            </TabsTrigger>
            <TabsTrigger value="permissions" title={t('permission-groups')}>
              {t('permission-groups')}
            </TabsTrigger>
            <TabsTrigger value="notification" title={t('notifications')}>
              {t('notifications')}
            </TabsTrigger>
          </>
        )}
      </TabsList>
      <TabsContent value="details" className="flex max-h-full w-full flex-col overflow-y-hidden">
        <UserDetailsTab />
      </TabsContent>
      <TabsContent value="auth" className="flex max-h-full w-full flex-col overflow-y-hidden">
        <AuthTab handleHideForm={() => console.log('')} user={getUserQuery.data} />
      </TabsContent>
      <TabsContent value="permissions" className="flex max-h-full w-full flex-col overflow-y-hidden">
        <UserPermissionsTab isLoading={getUserQuery.isLoading} user={getUserQuery.data} />
      </TabsContent>
      <TabsContent value="notification" className="flex max-h-full w-full flex-col overflow-y-hidden">
        <NotificationConfigTab userId={idParam ?? ''} />
      </TabsContent>
    </Tabs>
  );
};
