import React from 'react';

import { cn } from '@/lib/utils';
import { LoaderCircle } from 'lucide-react';

type Props = {
  isLoading: boolean;
  children?: React.ReactNode;
  size?: number;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Loading = ({ isLoading, children, size = 50, className, ...props }: Props) => {
  if (!isLoading) return <>{children}</>;

  return (
    <div className={cn('relative', className)} {...props}>
      {children}
      <div className="absolute top-0 flex h-full w-full items-center justify-center bg-gray-400/20 text-primary">
        <span className="animate-spin">
          <LoaderCircle size={size} />
        </span>
      </div>
    </div>
  );
};

export default Loading;
