import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaLock } from 'react-icons/fa';

import { useAbility } from '@/hooks/ability';
import { cn } from '@/lib/utils';

type Props = {
  permission?: string | string[];
  needAllPermissions?: boolean;
  children: React.ReactNode;
  className?: string;
  description?: string;
};

const PermissionedComponent = ({ permission, children, needAllPermissions, className, description }: Props) => {
  const ability = useAbility();
  const { t } = useTranslation();

  const hasPermission = Array.isArray(permission)
    ? needAllPermissions
      ? permission.every(p => ability.can(p, ''))
      : permission.some(p => ability.can(p, ''))
    : ability.can(permission, '');

  if (!permission || !permission.length || hasPermission) {
    return <>{children}</>;
  }
  return (
    <div
      className={cn(
        'relative w-full overflow-hidden overflow-y-auto rounded-md bg-gray-400/20 py-14 text-lg',
        className,
      )}
      title={Array.isArray(permission) ? permission.join(', ') : permission}
    >
      <span className="flex flex-col items-center gap-1 text-primary">
        <div className="flex items-center gap-2">
          <FaLock />
          {t('noPermission')}
        </div>
        <span className="text-center text-sm">{description}</span>
      </span>
    </div>
  );
};

export default PermissionedComponent;
