import { IntegrationDataProvider } from '../../@types/data/IntegrationDataProvider';
import { formatValuesToFormData } from '../form';
import api from './api';

export default {
  users: {
    get: async (params: object = null): Promise<any> => {
      return api.get('admin/user', params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`admin/user/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`admin/user`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`admin/user/${id}`, params);
    },
    getLog: async (id: string, params: object = null): Promise<any> => {
      return api.get(`admin/user/${id}/log`, params);
    },
    TwoFAClear: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`auth/two-factor-authentication/clear/${id}`, params);
    },
    notifications: {
      all: async (id: string, params: object = null): Promise<any> => {
        return api.get(`admin/user/${id}/notification/all`, params);
      },
      get: async (id: string, params: object = null): Promise<any> => {
        return api.get(`admin/user/${id}/notification`, params);
      },
      read: async (notification_id: string, params: object = null): Promise<any> => {
        return api.patch(`admin/user/notification/${notification_id}`, params);
      },
      readAll: async (id: string, params: object = null): Promise<any> => {
        return api.put(`admin/user/${id}/notification`, params);
      },
      removeAll: async (id: string, params: object = null): Promise<any> => {
        return api.delete(`admin/user/${id}/notification`, params);
      },
    },
  },
  permissions: {
    get: async (params: object = null): Promise<any> => {
      return api.get('admin/permission', params);
    },
    getTree: async (params: object = null): Promise<any> => {
      return api.get(`admin/permission/tree`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('admin/permission', params);
    },
    update: async (id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.post(`admin/permission/${id}`, paramsApi);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`admin/permission/${id}`, params);
    },
  },

  taxes: {
    get: async (params: object = null): Promise<any> => {
      return api.get('admin/tax', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`admin/tax/${id}`, params);
    },
  },

  position: {
    get: async (params: object = null): Promise<any> => {
      return api.get('admin/position', params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get('admin/position/all', params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('admin/position', params);
    },
    update: async (id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.post(`admin/position/${id}`, paramsApi);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`admin/position/${id}`, params);
    },
  },

  teams: {
    get: async (params: object = null): Promise<any> => {
      return api.get('admin/team', params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get('admin/team/all', params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('admin/team', params);
    },
    update: async (id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.post(`admin/team/${id}`, paramsApi);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`admin/team/${id}`, params);
    },
  },

  roles: {
    get: async (params: object = null): Promise<any> => {
      return api.get('admin/role', params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('admin/role', params);
    },
    update: async (id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.post(`admin/role/${id}`, paramsApi);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`admin/role/${id}`, params);
    },
  },

  changelog: {
    get: async (params: object = null): Promise<any> => {
      return api.get('admin/changelog', params);
    },
    latest: async (params: object = null): Promise<any> => {
      return api.get('admin/changelog/latest', params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('admin/changelog', params);
    },
    update: async (id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
        };
      }
      return api.put(`admin/changelog/${id}`, paramsApi);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`admin/changelog/${id}`, params);
    },
  },

  regions: {
    all: async (params: object = null): Promise<any> => {
      return api.get('admin/region/all', params);
    },
  },

  subsidiaries: {
    all: async (params: object = null): Promise<any> => {
      return api.get('admin/subsidiary/all', params);
    },
  },

  getIntegrationHistory: async (params = {}) => {
    return api.get<IntegrationDataProvider>(`admin/integration/import`, params);
  },
  forceIntegration: async (id: string) => {
    return api.get(`admin/integration/import/${id}`);
  },
  integrations: {
    uploadFile: async (data: any, params = {}) => {
      return api.post('limit-exposures/gap173/import', data, params);
    },
    getFileList: async (params = {}) => {
      return api.get('file-upload/list', params);
    },
  },
};
